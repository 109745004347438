<template>
  <hr class="c-divider" />
</template>

<style scoped>
.c-divider {
  flex-shrink: 0;
  height: 1px;
  margin: 0;
  border: none;
}
</style>
